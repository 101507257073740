<template>
  <div>
    <div v-if="externalSoftware != 2" style="font-size: 22px; font-weight: bold; padding: 60px">
      Dieser Export kann nur in Kombination mit der DATEV LV-Software verwendet werden
    </div>
    <SotecDashboard v-if="externalSoftware == 2"></SotecDashboard>
  </div>
</template>
<script>
import SotecDashboard from "./SotecDashboard.vue";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    SotecDashboard,
  },
  name: "ExportSotecDashboard",
  data() {
    return {
      mode: "daily",
    };
  },
  computed: {
    ...mapGetters(["externalSoftware"]),
  },
  watch: {},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh: function () {},
  },
};
</script>
<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped></style>
