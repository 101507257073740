<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left">
        <dateselectionheader :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')" v-if="tabMode == 1 && !loadingData" />
        <div v-if="tabMode == 1 && loadingData"><i class="fas fa-circle-notch fa-spin"></i> Daten werden geladen</div>
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right">
        <headerbuttonstack :state="sideButtons" />
      </div>
    </div>

    <div class="container mt-4 px-4 text-center">
      <div class="row gx-5">
        <div class="col">
          <div class="p-3 border bg-light" v-bind:class="tabMode == 0 ? 'activeTab' : 'inactiveTab'" v-on:click="tabMode = 0">Dashboard</div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light" v-bind:class="tabMode == 0 ? 'inactiveTab' : 'activeTab'" v-on:click="tabMode = 1">Archiv</div>
        </div>
      </div>
    </div>

    <div class="row mt-5 justify-content-center" v-if="tabMode == 0">
      <hr />
      <div class="col-10">
        <table class="table table-striped table-borderless" style="width: 100%; margin: 0 auto">
          <tr>
            <th class="documentsTh">Monatsabschluss durchgeführt</th>
            <th class="documentsTh">Standort</th>
            <th class="documentsTh">Monat</th>
            <th class="documentsTh">Jahr</th>
            <th class="documentsTh">Status</th>
            <th class="documentsTh" style="text-align: center">Fehlerbehebung</th>
            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-repeat" style="font-size: 16px;"></i>
            </th>

            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-cloud-upload" style="font-size: 16px;"></i>
            </th>
            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-file-download" style="font-size: 16px;"></i>
            </th>
            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-file-pdf" style="font-size: 16px;"></i>
            </th>

            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-ban" style="font-size: 16px;"></i>
            </th>
          </tr>
          <tr v-if="loadingData">
            <td colspan="10"><i class="fas fa-circle-notch fa-spin"></i> Die Daten werden aktualisiert</td>
          </tr>
          <tr v-if="interactionNeeded.length == 0 && !loadingData">
            <th class="documentsTd" colspan="8" style="text-align: center; font-size: 15px">
              <strong>Derzeit sind keine Exporte verfügbar</strong>
            </th>
          </tr>
          <tr v-for="row in interactionNeeded" v-if="!loadingData" v-bind:key="row.id">
            <th class="documentsTd">
              {{ row.dateMoment.format("DD.MM.YYYY HH:mm") }}
            </th>
            <th class="documentsTd">
              {{ storeNameAndDescription(row.storeId) }}
            </th>
            <th class="documentsTd">{{ monthNames[row.month - 1] }}</th>
            <th class="documentsTd">{{ row.year }}</th>
            <th class="documentsTd">{{ states[row.state] }}</th>
            <th class="documentsTd" style="text-align: center">
              <buttonc v-if="row.state == 89 || row.state == 92" :label="'Zu den Lohnarten'" :active="true" @click="navigateToPage('wagetypes')" style="margin-top: -5px" />
              <buttonc v-if="row.state == 95" :label="'Zu den Einstellungen'" :active="true" @click="navigateToPage('settings')" style="margin-top: -5px" />
              <buttonc v-if="row.state == 96" :label="'Personalnummern eintragen'" :active="true" @click="showEmployeesWithoutIdModal(row.id, row.errorJson)" style="margin-top: -5px" />
              <span v-if="row.state == 85">Durchschnittswerte eintragen</span>
              <span v-if="row.state == 90 || row.state == 94 || row.state == 98 || row.state == 87">Support kontaktieren!</span>
              <span v-if="row.state == 93">Versuchen Sie den Export in wenigen Minuten erneut zu starten</span>
            </th>

            <th class="documentsTd" style="text-align: center">
              <buttonc v-if="canRetry(row.state)" :label="'Export neu starten'" :active="true" @click="sendReset(row.id)" style="margin-top: -5px" />
            </th>

            <th class="documentsTd" style="text-align: center">
              <buttonc v-if="row.state == 3 || row.state == 94" :label="'hochladen'" :active="true" @click="sendUpload(row.id)" style="margin-top: -5px" />
            </th>

            <th class="documentsTd" style="text-align: center">
              <buttonc
                v-if="row.state == 3 || row.state == 94"
                :label="'herunterladen'"
                :active="true"
                @click="checkJsonData(row)"
                style="margin-top: -5px"
              />
            </th>

            <th class="documentsTd" style="text-align: center">
              <buttonc
                v-if="row.state == 3 || row.state == 94"
                :label="'PDF anzeigen'"
                :active="true"
                @click="checkJsonData(row, true)"
                style="margin-top: -5px"
              />
            </th>

            <th class="documentsTd" style="text-align: center">
              <buttonc v-if="canRetry(row.state) || row.state == 1 || row.state == 3 || row.state == 94" :label="'abbrechen'" :active="true" @click="sendAbort(row.id)" style="margin-top: -5px" />
            </th>
          </tr>
        </table>
      </div>
    </div>

    <div class="row mt-5 justify-content-center" v-if="tabMode == 1">
      <hr />
      <div class="col-10">
        <table class="table table-striped table-borderless" style="width: 100%; margin: 0 auto">
          <tr>
            <th class="documentsTh">Monatsabschluss durchgeführt</th>
            <th class="documentsTh">Standort</th>
            <th class="documentsTh">Status</th>
            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-file-download" style="font-size: 16px;"></i>
            </th>
            <th class="documentsTh" style="text-align: center">
              <i class="fas fa-file-pdf" style="font-size: 16px;"></i>
            </th>
          </tr>
          <tr v-if="loadingData">
            <td colspan="6"><i class="fas fa-circle-notch fa-spin"></i> Die Daten werden aktualisiert</td>
          </tr>
          <tr v-if="storesExportMap.length == 0 && !loadingData">
            <th class="documentsTd" colspan="3" style="text-align: center; font-size: 15px">
              <strong>In diesem Monat sind keine Exporte verfügbar</strong>
            </th>
          </tr>
          <tr v-for="row in storesExportMap" v-if="!loadingData" v-bind:key="row.id">
            <th class="documentsTd">
              {{ row.dateMoment.format("DD.MM.YYYY HH:mm") }}
            </th>
            <th class="documentsTd">
              {{ storeNameAndDescription(row.storeId) }}
            </th>
            <th class="documentsTd">{{ states[row.state] }}</th>
            <th class="documentsTd" style="text-align: center">
              <buttonc
                :label="'herunterladen'"
                :active="true"
                @click="checkJsonData(row)"
                style="margin-top: -5px"
              />
            </th>
            <th class="documentsTd" style="text-align: center">
              <buttonc
                :label="'PDF anzeigen'"
                :active="true"
                @click="checkJsonData(row, true)"
                style="margin-top: -5px"
              />
            </th>
          </tr>
        </table>
      </div>
    </div>

    <b-modal size="md" ref="modal-employeesWithoutId" @close="closeEmployeesWithoutIdModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Bei folgenden MitarbeiterInnen fehlt die Personalnummer</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table v-if="!filledAllEmployeeIds()" style="width: 100%">
            <tr>
              <th style="width: 50%; font-family: DistrictProBold">MitarbeiterIn</th>
              <th style="width: 50%; font-family: DistrictProBold">Personalnummer</th>
            </tr>
            <tr v-for="emp in employeesWithoutIdInput" v-bind:key="emp.id" class="my-1" style="text-align: left">
              <td>
                {{ emp.lastName + " " + emp.firstName }}
              </td>
              <td>
                <input v-model="emp.mdId" />
              </td>
            </tr>
          </table>
          <p v-if="filledAllEmployeeIds()">Die Personalnummern wurden bereits eingetragen. Bitte starten Sie den Export erneut.</p>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div v-if="!filledAllEmployeeIds()" class="button right buttonml" @click="saveEmployeesWithoutIdModal()">
          <div class="label labelml">Speichern</div>
        </div>
        <div v-if="filledAllEmployeeIds()" class="button right buttonml" @click="closeEmployeesWithoutIdModal(), sendReset(currentRowId)">
          <div class="label labelml">Export neu starten</div>
        </div>
        <div class="button right" @click="closeEmployeesWithoutIdModal()">
          <div class="label">Abbrechen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="md" ref="modal-loadAverageWageReport" @close="" :no-close-on-backdrop="true" :no-close-on-esc="true" centered>
      <template slot="modal-header">
        <div></div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="label left" style="text-align: center">
            <i class="fas fa-spinner fa-spin" style="font-size: xxx-large"></i> <br/><br/>
            Bitte warten<br />
            Stundenlohn Report wird erzeugt<br />
            Je nach Einstellungen kann dieser Prozess einige Sekunden in Anspruch nehmen<br />
          </div>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>

    <b-modal size="md" ref="modal-loadCurrentValuesPdf" @close="" :no-close-on-backdrop="true" :no-close-on-esc="true" centered>
      <template slot="modal-header">
        <div></div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="label left" style="text-align: center">
            <i class="fas fa-spinner fa-spin" style="font-size: xxx-large"></i> <br/><br/>
            Bitte warten<br />
            PDF Report mit den aktuellen Werten wird erzeugt<br />
            Dieser Prozess kann einige Sekunden in Anspruch nehmen<br />
          </div>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>

    <b-modal ref="modal-pdfpreview" @close="closePdfPreview()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Report erstellt</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left;">
          <div>
            <span>Report wurde erstellt und steht zum Öffnen oder Herunterladen bereit.</span>
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closePdfPreview()">
          <div class="label">
            Schließen
          </div>
        </div>
        <div class="button ml right" @click="openPreviewedPdf()">
          <div class="label">
            In neuem Tab öffnen
          </div>
        </div>
        <div class="button ml right" @click="savePreviewedPdf()">
          <div class="label">
            Speichern
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import VueEventBus from "../../vue-event-bus";
import {mapGetters} from "vuex";
import 'jspdf-autotable';
import moment from "moment";

export default {
  name: "SotecDashboard",
  components: {},
  data() {
    return {
      tabMode: 0,
      selectedStore: null,
      stores: [],
      contracts: [],

      loadingData: true,

      exports: [],
      storesExportMap: {},


      interactionNeeded: [],
      loading: true,
      loadingText: "Daten werden geladen...",
      selectedDate: this.$helpers.getMomentTZ().clone(),

      companyData: null,
      storeData: null,
      timeframeStart: this.$helpers.getMomentTZ().clone().startOf("month"),
      timeframeEnd: this.$helpers.getMomentTZ().clone().endOf("month"),

      softwareId: 0,

      employeesWithoutId: [],
      employeesWithoutIdInput: [],
      currentRowId: 0,

      states: {
        0: "Export wird berechnet",
        1: "Export wird erneut berechnet",
        2: "Export erfolgreich hochgeladen",
        3: "Export pausiert - Inhaltsüberprüfung erforderlich",
        4: "Export wird zu Sotec hochgeladen",

        85: "Export pausiert - Es fehlen monatliche Durchschnittswerte",
        86: "Export abgebrochen - Es ist kein Inhalt im Export vorhanden",
        87: "Export pausiert - Die Store-ID konnte nicht von MD abgefragt werden",
        88: "Export abgebrochen - Der Benutzer hat den Export abgebrochen",
        89: "Export pausiert - Es sind keine Lohnartdetails ausgefüllt",
        90: "Export pausiert - Unbekannter Fehler, bitte Support kontaktieren",
        91: "Export abgebrochen - Dieser Export ist zum Zeitpunkt der Ausführung veraltet",
        92: "Export pausiert - Eine der Lohnarten besitzt keine eindeutige Id",
        93: "Export pausiert - Technischer Fehler bei der Datenabfrage",
        94: "Export pausiert - Upload zu Sotec war nicht möglich",
        95: "Export pausiert - Firmennummer ist nicht eingetragen",
        96: "Export pausiert - Mitarbeiterdaten unvollständig",
        97: "Export abgebrochen: Monatsabschluss nicht gemacht",
        98: "Export pausiert: LV-Software ist nicht auf DATEV eingestellt",
        99: "Export abgebrochen: Übertragungsfehler des Datums",
      },

      monthNames: {
        0: "Jänner",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "November",
        11: "Dezember",
      },

      models: [],
      wageTypes: []
    };
  },
  created: function () {
    VueEventBus.$on("SotecDatevExportEvent:jobEnded", this.jobEnded);
  },
  beforeDestroy: function () {
    VueEventBus.$off("SotecDatevExportEvent:jobEnded", null);
  },
  mounted() {
    let view = this;
    view.loading = true;
    this.refresh(true);
  },
  watch: {
    companyId: function (val, oldVal) {
      this.selectedStore = null;
      this.stores = [];
      this.refresh(true);
    },
    selectedStore: function (val, oldVal) {
      /*if (val != null) this.refresh(true);*/
    },
  },
  computed: {
    ...mapGetters(["companyId", "isSuperUser", "employeeMap", "storeArray", "selectedStores"]),
    dateDisplay: function () {
      let view = this;
      if (view.storeData != null) {
        if (view.softwareId == 2) {
          return view.monthNames[view.timeframeStart.month()] + " " + view.timeframeStart.format("YYYY");
        }
      }
      return "-";
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Neu laden",
      });


      if (this.isSuperUser) {
        state.buttons.push({
          type: "block green",
          icon: "fa-share-square",
          click: () => {
            view.openForceSend();
          },
          tooltip: "Export sofort auslösen",
        });
      }


      state.buttons.push({
        type: "block",
        icon: "fa-file-pdf",
        click: () => {
          view.openGetCurrentValuesPdfPopup();
        },
        tooltip: "Aktuelle Werte als PDF anzeigen",
      });
      return state;
    },
    sideButtons() {
      //
      // <buttonc v-if="storeData != null" type="square" icon="fa-info"
      //          v-tooltip="'Gewählte Software anzeigen'" @click = "showChosenSoftwarePopup" / >

      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-bars",
        tooltip: "Zusätzliche Optionen",
        click: ($event) => {
          view.OpenMenuDetails($event, this.burgerMenuState);
        },
      });
      return state;
    },
    burgerMenuState(){
      let view = this;
      let state = {
        componentref: 'burgermenustate',
        groups: [],
        items: []
      };

      state.items = [
        {
          closeOnClick: true,
          icon: "fa-info",
          show: view.storeData != null,
          text: 'Gewählte Software anzeigen',
          click: () => { view.showChosenSoftwarePopup(); },
        },
        {
          closeOnClick: true,
          icon: "fa-file-pdf",
          text: 'Stundenlohn Report generieren',
          show:  true,
          disabled: false,
          click: () => { view.showGenerateAverageWageReportPopup(); }
        },
      ];

      return state;
    },
    getPdfOptionsStoreLocalStorage() {
      let pdfOptionsLocalStorage = JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions"));
      return pdfOptionsLocalStorage ? pdfOptionsLocalStorage.store : null;
    },
    getPdfOptionsEmploymentModelsLocalStorage() {
      let view = this;

      let pdfOptionsLocalStorage = JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions"));
      let employmentModelsLocalStorage = pdfOptionsLocalStorage ? pdfOptionsLocalStorage.employmentModels : [];

      return view.models.map(m => (
          {
            value: m.id,
            label: m.name,
            selected: employmentModelsLocalStorage.length > 0 ? employmentModelsLocalStorage.includes(m.id) : true
          }
      ));
    },
    getPdfOptionsWageTypesLocalStorage() {
      let view = this;

      let pdfOptionsLocalStorage = JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions"));
      let wageTypesLocalStorage = pdfOptionsLocalStorage ? pdfOptionsLocalStorage.wageTypes : [];

      return view.wageTypes.map(wt => (
          {
            value: wt.id,
            label: wt.name,
            selected: wageTypesLocalStorage.length > 0 ? wageTypesLocalStorage.includes(wt.id) : true
          }
      ));
    },
  },
  methods: {
    storeNameAndDescription(id) {
      for (let i in this.storeArray) {
        let s = this.storeArray[i];
        if (s.id == id) {
          return s.name + " / " + s.description;
        }
      }
      return "Kostenstelle nicht gefunden";
    },
    reset: function () {
      let view = this;
      view.companyData = null;
      view.actualShifts = [];
      view.exportableTableActualShifts = [];
      view.hiddenTableActualShifts = [];

      view.storesExportMap = {};
    },

    navigateToPage: function (mode) {
      let view = this;
      if (mode == "wagetypes") {
        view.$router.push({
          path: "../company/IndividualWageTypes",
          query: {},
        });
      } else if (mode == "settings") {
        view.$router.push({ path: "../company/CompanySettings", query: {} });
      } else if (mode == "employees") {
        view.$router.push({
          path: "../documents/employeedocuments",
          query: {},
        });
      }

      /*
      <buttonc v-if="row.state == 89 || row.state == 92" :label="'Zu den Lohnarten'" :active="true" @click="navigate('wagetypes')" style="margin-top: -5px" />
              <buttonc v-if="row.state == 95 || row.state == 98" :label="'Zu den Einstellungen'" :active="true" @click="navigate('settings')" style="margin-top: -5px" />
              <buttonc v-if="row.state == 96" :label="'Zu den Mitarbeitern'" :active="true" @click="navigate('employees')" style="margin-top: -5px" />
              */
    },

    canRetry: function (stateId) {
      switch (stateId) {
        case 99:
        case 97:
        case 91:
          return false;
        case 98:
        case 96:
        case 95:
        case 93:
        case 92:
        case 90:
        case 89:
        case 87:
        case 85:
          return true;
      }
    },
    getExports: function () {
      let view = this;
      let url = "/api/sec/sotecExports";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/sotecExports");
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    getExport: function (exportId) {
      let view = this;
      let url = "/api/sec/sotecExports";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/sotecExports");
      url = this.$helpers.appendParam("exportId", exportId, url, "/api/sec/sotecExports");
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    getExportFull: function(id) {
      let view = this;
      let baseUrl = "/api/sec/sotecExports/full";
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("exportId", id, url, baseUrl);
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    forceSend: function(entity){
      let view = this;
      console.log("SEND FORCE");

      let url = "/api/sec/sotecExports/forceRun";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/sotecExports/forceRun");
      url = this.$helpers.appendParam("year", entity.year, url, "/api/sec/sotecExports/forceRun");
      url = this.$helpers.appendParam("month", entity.month, url, "/api/sec/sotecExports/forceRun");
      url = this.$helpers.appendParam("storeId", entity.store, url, "/api/sec/sotecExports/forceRun");
      return this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      }).then(response => {
        console.log(response.data);
        let sotecExport = response.data;
        let tmpMoment = view.$helpers.getMomentFromStringTZ(sotecExport.created_at);
        sotecExport.dateMoment = tmpMoment;

        let tmpMonth = sotecExport.month - 1;
        let tmpYear = sotecExport.year;
        if (tmpMonth == 0) {
          tmpMonth = 11;
          tmpYear--;
        }

        if (sotecExport.month - 1 == view.selectedDate.month() && sotecExport.year == view.selectedDate.year()) {
          view.storesExportMap.unshift(sotecExport);
        }

        if (view.canRetry(sotecExport.state) || sotecExport.state == 94 || sotecExport.state == 3) {
          view.interactionNeeded.unshift(sotecExport);
        } else if (sotecExport.state == 0 || sotecExport.state == 1 || sotecExport.state == 4) {
          view.interactionNeeded.unshift(sotecExport);
        }
        view.$helpers.success("Export gestartet", "Export wurde erfolgreich angestoßen und wird berechnet.");
      }).catch(error => {
        console.log("ERROR", error);
        view.$helpers.error("Export fehlgeschlagen", "Export konnte nicht angestoßen werden.");
      });

    },

    openForceSend() {
      let view = this;

      let years = [
        {value: 2022, label: '2022'},
        {value: 2023, label: '2023'},
        {value: 2024, label: '2024'},
        {value: 2025, label: '2025'},
      ];
      let months = [
        {value: 1, label: 'Jänner'},
        {value: 2, label: 'Februar'},
        {value: 3, label: 'März'},
        {value: 4, label: 'April'},
        {value: 5, label: 'Mai'},
        {value: 6, label: 'Juni'},
        {value: 7, label: 'Juli'},
        {value: 8, label: 'August'},
        {value: 9, label: 'September'},
        {value: 10, label: 'Oktober'},
        {value: 11, label: 'November'},
        {value: 12, label: 'Dezember'}
      ];
      let stores = [];
      for (let i in this.storeArray) {
        let s = this.storeArray[i];
        stores.push({value: s.id,label: s.name+" / "+s.description});
      }

      let dataEntries = [
        {
          label: "Jahr",
          key: "year",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: years,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: 2022,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: 2022,
        },
        {
          label: "Monat",
          key: "month",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: months,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        {
          label: "Standort",
          key: "store",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: stores,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: fibu.sorting, slider: {value: fibu.sorting, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.forceSend(eEntity);
          },
        },
        header: "Export sofort generieren",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openGetCurrentValuesPdfPopup() {
      let view = this;

      let tmpMoment = view.$helpers.getCurrentMonthMomentTZ();

      let years = [];
      for (let i = -1; i < 3; i++) {
        let current = tmpMoment.year() - i;
        years.push({value: current, label: current + ""});
      }

      let months = [
        {value: 1, label: 'Jänner'},
        {value: 2, label: 'Februar'},
        {value: 3, label: 'März'},
        {value: 4, label: 'April'},
        {value: 5, label: 'Mai'},
        {value: 6, label: 'Juni'},
        {value: 7, label: 'Juli'},
        {value: 8, label: 'August'},
        {value: 9, label: 'September'},
        {value: 10, label: 'Oktober'},
        {value: 11, label: 'November'},
        {value: 12, label: 'Dezember'}
      ];
      let stores = [];
      for (let i in this.storeArray) {
        let s = this.storeArray[i];
        stores.push({value: s.id,label: s.name+" / "+s.description});
      }

      let dataEntries = [
        {
          label: "Session ID",
          key: "sessionId",
          hidden: true,
          value: view.sessionId,
        },
        {
          label: "Jahr",
          key: "year",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: years,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: years.find(y => { return y.value === tmpMoment.year() }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: years.find(y => { return y.value === tmpMoment.year() }).value,
        },
        {
          label: "Monat",
          key: "month",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: months,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: months.find(y => { return y.value === tmpMoment.month()+1 }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: months.find(y => { return y.value === tmpMoment.month()+1 }).value,
        },
        {
          label: "Standort",
          key: "storeId",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            input: "",
            items: stores,
            selectMultiple: false,
            selectedItem: view.getPdfOptionsStoreLocalStorage && stores.find(s => { return s.value === view.getPdfOptionsStoreLocalStorage })
              ? stores.find(s => { return s.value === view.getPdfOptionsStoreLocalStorage }) : stores[0],
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      view.getPdfOptions().forEach(option => {
        dataEntries.push(option);
      });
      dataEntries.push({ flexDivider: true, styles: { "margin-bottom": "23px" } });

      let errorCheck = {};
      dataEntries.forEach(dataEntry => {
        if (dataEntry.key) {
          errorCheck[dataEntry.key] = (entries) => {
            let entry = entries[dataEntry.key];
            if (entry === '' || entry === undefined || entry === null || (Array.isArray(entry) && entry.length === 0))
              return {
                key: dataEntry.key,
                type: "error",
                text: dataEntry.label + " muss ausgewählt sein.",
              };
            else return null;
          };
        }
      });

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (entity) {
            view.$refs["modal-loadCurrentValuesPdf"].show();
            localStorage.setItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions", JSON.stringify(entity));
            let promises = [];
            promises.push(view.$helpers.calculateIndividualWageTypeExport(entity));
            //if (entity.employeeSelection == 2) {
              promises.push(view.getEmployeeContracts());
            //}
            Promise.all(promises)
            .then((response) => {
              let entry = {
                storeId: entity.storeId,
                month: entity.month,
                year: entity.year,
                options: entity,
                exportJson: JSON.stringify(response[0].data),
              }
              view.$refs["modal-loadCurrentValuesPdf"].hide();
              view.generatePDF(entry);
            })
            .catch((error) => {
              view.$refs["modal-loadCurrentValuesPdf"].hide();
              console.log(error);
              console.log(error.response);
              if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.message === "store not enabled") {
                  view.$helpers.error("Standort nicht aktiviert für Sotec-Export", "Der Standort kann in den Einstellungen für den Sotec-Export aktiviert werden.");
                } else {
                  view.$helpers.error("Fehler", "Beim Erstellen des PDFs ist ein Fehler aufgetreten.");
                }
              } else {
                view.$helpers.error("Fehler", "Beim Erstellen des PDFs ist ein Fehler aufgetreten.");
              }
            });
          },
          errorCheck: errorCheck,
        },
        header: "Aktuelle Werte als PDF anzeigen",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },


    checkJsonData(entry, pdf = false) {
      let fullEntry = null;
      if (!entry.hasOwnProperty("deltaJson") || !entry.hasOwnProperty("exportJson")) {
        return this.getExportFull(entry.id)
          .then((response) => {
            fullEntry = response.data;

            let tempEntry = this.storesExportMap.find(e => e.id === fullEntry.id);
            if (tempEntry !== undefined) {
              tempEntry.deltaJson = fullEntry.deltaJson;
              tempEntry.exportJson = fullEntry.exportJson;
            }
            let tempEntry2 = this.interactionNeeded.find(e => e.id === fullEntry.id);
            if (tempEntry2 !== undefined) {
              tempEntry2.deltaJson = fullEntry.deltaJson;
              tempEntry2.exportJson = fullEntry.exportJson;
            }

            if (pdf) {
              this.openCreatePdfPopup(fullEntry);
            } else {
              this.downloadCSV(fullEntry);
            }
          });
      } else {
        if (pdf) {
          this.openCreatePdfPopup(entry);
        } else {
          this.downloadCSV(entry);
        }
      }
    },

    downloadCSV(entry) {
      let view = this;

      let delta = entry.deltaJson;
      let real = entry.exportJson;

      let finalCSV = "Personalnummer;Transaktions Datum;EarnCode;Tage;Stunden;Faktor;Entgelt;Vorname;Nachname;Beschreibung\n";

      let obj = JSON.parse(delta);
      if (obj != null) {
        for (let i in obj["employees"]) {
          let employee = obj["employees"][i];
          for (let j in employee["days"]) {
            let day = employee["days"][j];
            for (let k in day["wageTypes"]) {
              let wageType = day["wageTypes"][k];

              let days = parseFloat(wageType["shifts"] * -1.0).toFixed(8);
              let hours = parseFloat(wageType["hours"] * -1.0).toFixed(8);
              let factorString = parseFloat(wageType["factor"] * 1.0).toFixed(8);
              let amount = parseFloat(wageType["hours"] * -1.0 * wageType["factor"]).toFixed(8);

              let line =
                employee.mdPersonnelId +
                ";" +
                day["date"] +
                ";" +
                wageType["obj"]["numberString"] +
                ";" +
                days.replace(".", ",") +
                ";" +
                hours.replace(".", ",") +
                ";" +
                factorString.replace(".", ",") +
                ";" +
                amount.replace(".", ",") +
                ";" +
                employee["firstName"] +
                ";" +
                employee["lastName"] +
                ";" +
                wageType["obj"]["name"] +
                "\n";
              if(days != 0 || hours != 0 || amount != 0){
                finalCSV = finalCSV + line;
              }
            }
          }
        }
      }

      obj = JSON.parse(real);
      if (obj != null) {
        for (let i in obj["employees"]) {
          let employee = obj["employees"][i];
          for (let j in employee["days"]) {
            let day = employee["days"][j];
            for (let k in day["wageTypes"]) {
              //console.log(wageType);
              let wageType = day["wageTypes"][k];

              let days = parseFloat(wageType["shifts"] * 1.0).toFixed(8);
              let hours = parseFloat(wageType["hours"] * 1.0).toFixed(8);
              let factorString = parseFloat(wageType["factor"] * 1.0).toFixed(8);
              let amount = parseFloat(wageType["hours"] * 1.0 * wageType["factor"]).toFixed(8);

              let line =
                employee.mdPersonnelId +
                ";" +
                day["date"] +
                ";" +
                wageType["obj"]["numberString"] +
                ";" +
                days.replace(".", ",") +
                ";" +
                hours.replace(".", ",") +
                ";" +
                factorString.replace(".", ",") +
                ";" +
                amount.replace(".", ",") +
                ";" +
                employee["firstName"] +
                ";" +
                employee["lastName"] +
                ";" +
                wageType["obj"]["name"] +
                "\n";
              if(days != 0 || hours != 0 || amount != 0){
                finalCSV = finalCSV + line;
              }
            }
          }
        }
      }
      view.forceFileDownload(finalCSV, "Export.csv");
    },
    getPdfOptions() {
      let view = this;

      let employeeSelectionItems = [
        {
          label: "Alle Mitarbeiter",
          value: 1
        },
        {
          label: "Alle Mitarbeiter mit aktivem Arbeitsvertrag",
          value: 2
        },
        {
          label: "Nur Mitarbeiter mit ausgewählten Werten",
          value: 3
        }
      ];

      return [
        {
          label: "Gruppierung",
          key: "groupBy",
          hidden: false,
          type: "dropdownsearch",
          value: 0,
          selectMultiple: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: [
              {
                label: "Nicht gruppieren",
                value: 0
              },
              {
                label: "Nach Beschäftigungsgruppe gruppieren",
                value: 1
              }
            ],
            selectMultiple: false,
            selectFirst: true,
            selectedItems: [],
            styleLight: true
          }
        },
        {
          label: "Sortierung Mitarbeiter",
          key: "sortEmployeesBy",
          hidden: false,
          type: "dropdownsearch",
          value: 0,
          selectMultiple: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: [
              {
                label: "Nach Nachnamen sortieren",
                value: 0
              },
              {
                label: "Nach Personalnummer sortieren",
                value: 1
              }
            ],
            selectMultiple: false,
            selectFirst: true,
            selectedItems: [],
            styleLight: true
          }
        },
        {
          label: "Sortierung Einzelposten",
          key: "sortEntriesBy",
          hidden: false,
          type: "dropdownsearch",
          value: 0,
          selectMultiple: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: [
              {
                label: "Nach EarnCode sortieren",
                value: 0
              },
              {
                label: "Nach Datum sortieren",
                value: 1
              }
            ],
            selectMultiple: false,
            selectFirst: true,
            selectedItems: [],
            styleLight: true
          }
        },
        {
          label: "Mitarbeiterauswahl",
          key: "employeeSelection",
          hidden: false,
          type: "dropdownsearch",
          value: null,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: employeeSelectionItems,
            selectedItem: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") && JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employeeSelection
              ? employeeSelectionItems.find(e => e.value == JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employeeSelection)
              : employeeSelectionItems.find(e => e.value == 3),
            selectMultiple: false,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
        {
          label: "Alle Beschäftigungsgruppen",
          key: "employmentModelsBool",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employmentModelsBool : false
        },
        {
          label: "Alle Beschäftigungsgruppen abwählen",
          key: "employmentModelDeselectBool",
          hidden: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employmentModelsBool : false,
          type: "bool",
          value: false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let employmentModelsBool = datafill.entries.find(i => i.key == 'employmentModelsBool');
            if(employmentModelsBool.value == true) {
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = false;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: "Beschäftigungsgruppen",
          key: "employmentModels",
          hidden: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employmentModelsBool : false,
          type: "dropdownsearch",
          value: null,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: view.getPdfOptionsEmploymentModelsLocalStorage,
            selectMultiple: true,
            onceSelected: function ($event, item, searchbar) {
              let employmentModelDeselectBool = searchbar.$parent.entries.find(i => i.key == 'employmentModelDeselectBool');
              employmentModelDeselectBool.value = false;},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let employmentModelsBool = datafill.entries.find(i => i.key == 'employmentModelsBool');
            let employmentModelDeselectBool = datafill.entries.find(i => i.key == 'employmentModelDeselectBool');
            if(employmentModelsBool.value == true) {
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.dropdownSearchState.selectedItems = view.getPdfOptionsEmploymentModelsLocalStorage.forEach(model => model.selected = true);
            } else {
              entryBeforeUpdate.hidden = false;
              if (employmentModelDeselectBool.value == true) {
                entryBeforeUpdate.dropdownSearchState.selectedItems = view.getPdfOptionsEmploymentModelsLocalStorage.forEach(model => model.selected = false);
              }
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: "Alle Lohnarten",
          key: "wageTypesBool",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).wageTypesBool : false,
        },
        {
          label: "Alle Lohnarten abwählen",
          key: "wageTypesDeselectBool",
          hidden: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).wageTypesBool : false,
          type: "bool",
          value: false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let wageTypesBool = datafill.entries.find(i => i.key == 'wageTypesBool');
            if(wageTypesBool.value == true) {
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = false;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: "Lohnarten",
          key: "wageTypes",
          hidden: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).wageTypesBool : false,
          type: "dropdownsearch",
          value: null,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: view.getPdfOptionsWageTypesLocalStorage,
            selectMultiple: true,
            onceSelected: function ($event, item, searchbar) {
              let wageTypesDeselectBool = searchbar.$parent.entries.find(i => i.key == 'wageTypesDeselectBool');
              wageTypesDeselectBool.value = false;
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let wageTypesBool = datafill.entries.find(i => i.key == 'wageTypesBool');
            let wageTypesDeselectBool = datafill.entries.find(i => i.key == 'wageTypesDeselectBool');
            if(wageTypesBool.value == true) {
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.dropdownSearchState.selectedItems = view.getPdfOptionsWageTypesLocalStorage.forEach(model => model.selected = true);
            } else {
              entryBeforeUpdate.hidden = false;
              if (wageTypesDeselectBool.value == true) {
                entryBeforeUpdate.dropdownSearchState.selectedItems = view.getPdfOptionsWageTypesLocalStorage.forEach(model => model.selected = false);
              }
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: "Nur Summen anzeigen",
          key: "showOnlySums",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).showOnlySums : false,
        },
        {
          label: "Mitarbeiter Summen",
          key: "employeeSums",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employeeSums : true,
        },
        {
          label: "Beschäftigungsgruppe Summen",
          key: "employmentModelSums",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).employmentModelSums : true,
        },
        {
          label: "Lohnart Summen",
          key: "wageTypeSums",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).wageTypeSums : true,
        },
        {
          label: "Gesamt Summen",
          key: "totalSums",
          hidden: false,
          type: "bool",
          value: localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions") ? JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions")).totalSums : true,
        },
      ]
    },
    openCreatePdfPopup(entry) {
      let view = this;

      let dataEntries = [];
      view.getPdfOptions().forEach(option => {
        dataEntries.push(option);
      });

      let errorCheck = {};
      dataEntries.forEach(dataEntry => {
        if (dataEntry.key) {
          errorCheck[dataEntry.key] = (entries) => {
            let entry = entries[dataEntry.key];
            if (entry === '' || entry === undefined || entry === null || (Array.isArray(entry) && entry.length === 0))
              return {
                key: dataEntry.key,
                type: "error",
                text: dataEntry.label + " muss ausgewählt sein.",
              };
            else return null;
          };
        }
      });

      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (entity) {
            localStorage.setItem(this.$store.state.companyId + "_settings_sotecDashboard_pdfOptions", JSON.stringify(entity));
            entry.options = entity;
            if (!entry.exportJson) {
              view.$helpers.error("Fehler", "Export enthält keine Daten.");
            } else {
              if (true || entity.employeeSelection == 2) {
                view.getEmployeeContracts()
                .then((response) => {
                  view.generatePDF(entry);
                })
              } else {
                view.generatePDF(entry);
              }
            }
          },
          errorCheck: errorCheck
        },
        header: 'PDF Bericht - Einstellungen',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });



    },
    generatePDF(entry) {
      let view = this;

      let store = view.storeArray.find(s => s.id === entry.storeId);
      console.log(store);
      // console.log(view.storeArray);
      let [doc, filename] = this.$helpers.generateSotecExportPDF(entry, view.employeeMapById, view.contracts, view.models, store, view.userFullname);

      this.modalPdfType = 'Report';
      view.$refs["modal-loadAverageWageReport"].hide();
      this.previewPdf(doc, filename);
    },
    // TODO: use $helpers function
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    OpenMenuDetails($event, state){
      let view = this;

      this.$helpers.OpenPopup({
        type: 'burgermenu',
        componentState: state,
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },
    showChosenSoftwarePopup() {
      let softwareName = "nicht ausgewählt!";
      if (this.storeData != null) {
        switch (this.softwareId) {
          case 0:
            softwareName = "BMD";
            break;
          case 1:
            softwareName = "DPW / sage";
            break;
          case 2:
            softwareName = "DATEV über SOTEC";
            break;
        }
      }

      let info = "Für den Betrieb werden Daten verarbeitet für: " + softwareName;
      let dataEntries = [];
      dataEntries.push({
        key: "info",
        hidden: false,
        type: "output",
        value: info,
      });
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function () {
            console.log("Bye bye...");
          },
          positiveButtonLabel: "Schließen",
        },
        header: "Gewählte Software",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
        locked: false,
      });
    },
    showGenerateAverageWageReportPopup() {
      let view = this;

      let averageWageReportLocalStorage = JSON.parse(localStorage.getItem(this.$store.state.companyId + "_settings_sotecDashboard_averageWageReport"));

      let tmpMoment = view.$helpers.getCurrentMonthMomentTZ();
      let tmpMomentMinusOne = tmpMoment.clone().subtract(1, "months");

      let years = [];
      for (let i = 0; i < 3; i++) {
        let current = tmpMoment.year() - i;
        years.push({value: current, label: current + ""});
      }

      let months = [
        {value: 1, label: 'Jänner'},
        {value: 2, label: 'Februar'},
        {value: 3, label: 'März'},
        {value: 4, label: 'April'},
        {value: 5, label: 'Mai'},
        {value: 6, label: 'Juni'},
        {value: 7, label: 'Juli'},
        {value: 8, label: 'August'},
        {value: 9, label: 'September'},
        {value: 10, label: 'Oktober'},
        {value: 11, label: 'November'},
        {value: 12, label: 'Dezember'}
      ];
      let stores = [];
      let storesLocalStorage = averageWageReportLocalStorage ? averageWageReportLocalStorage.stores : [];
      for (let i in this.storeArray) {
        let s = this.storeArray[i];
        stores.push({value: s.id,label: s.name+" / "+s.description, selected: storesLocalStorage.length > 0 ? storesLocalStorage.includes(s.id) : true });
      }

      let wageTypesLocalStorage = averageWageReportLocalStorage ? averageWageReportLocalStorage.wageTypes : [];
      let wageTypes = view.wageTypes.filter(wt => wt.details && wt.details.workType === 0 && (wt.details.useBonus === 0 || wt.details.bonusMode !== 2))
        .map(wt => ({ value: wt.id, label: wt.name, selected: wageTypesLocalStorage.length > 0 ? wageTypesLocalStorage.includes(wt.id) : true }));

      let modelsLocalStorage = averageWageReportLocalStorage ? averageWageReportLocalStorage.models : [];
      let models = view.models.map(m => ({ value: m.id, label: m.name, selected: modelsLocalStorage.length > 0 ? modelsLocalStorage.includes(m.id) : true }));

      let labelStyles = {'width': '175px'};

      let dataEntries = [
        {
          label: "Jahr",
          labelStyles: labelStyles,
          key: "year",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: years,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: years.find(y => { return y.value === tmpMomentMinusOne.year() }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: years.find(y => { return y.value === tmpMomentMinusOne.year() }).value,
        },
        {
          label: "Monat",
          labelStyles: labelStyles,
          key: "month",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: months,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: months.find(y => { return y.value === tmpMomentMinusOne.month()+1 }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: months.find(y => { return y.value === tmpMomentMinusOne.month()+1 }).value,
        },
        {
          label: "Standort",
          labelStyles: labelStyles,
          key: "stores",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: stores,
            selectMultiple: true,
            selectedItems: stores,
            selectedItem: {},
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        {
          label: "Lohnarten",
          labelStyles: labelStyles,
          key: "wageTypes",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: wageTypes,
            selectMultiple: true,
            selectedItems: wageTypes,
            selectedItem: {},
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        {
          label: "Beschäftigungsgruppen",
          labelStyles: labelStyles,
          key: "models",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: models,
            selectMultiple: true,
            selectedItems: models,
            selectedItem: {},
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
        {
          label: "Beschäftigungsgruppen ohne Arbeitszeit inkludieren",
          labelStyles: labelStyles,
          dataStyles: {'justify-content': 'end'},
          key: "includeNullRows",
          hidden: false,
          type: "bool",
          value: averageWageReportLocalStorage && averageWageReportLocalStorage.includeNullRows,
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function (entity) {
            view.$refs["modal-loadAverageWageReport"].show();
            localStorage.setItem(this.$store.state.companyId + "_settings_sotecDashboard_averageWageReport", JSON.stringify(entity));
            view.generateAverageWageReport(entity)
              .then(response => {
                view.createAverageWageReportPdf(response.data, entity);
              })
              .catch(error => {
                view.$refs["modal-loadAverageWageReport"].hide();
                console.log(error);
                view.$helpers.error("Fehler", "Beim Erstellen des PDFs ist ein Fehler aufgetreten.");
              });
          },
          errorCheck: {
            'stores': (entries) => {
              if (entries.stores.length === 0)
                return {
                  key: 'stores',
                  type: 'error',
                  text: 'Bitte wählen Sie mindestens einen Standort'
                }
              else return null
            },
            'wageTypes': (entries) => {
              if (entries.wageTypes.length === 0)
                return {
                  key: 'wageTypes',
                  type: 'error',
                  text: 'Bitte wählen Sie mindestens eine Lohnart'
                }
              else return null
            },
            'models': (entries) => {
              if (entries.models.length === 0)
                return {
                  key: 'models',
                  type: 'error',
                  text: 'Bitte wählen Sie mindestens ein Beschäftigungsmodell'
                }
              else return null
            }
          },
          positiveButtonLabel: "Stundenlohn Report generieren",
          deleteCallback: function () {},
          negativeButtonLabel: "Abbrechen",
        },
        header: "Stundenlohn Report Einstellungen",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
        locked: false,
      });
    },

    generateAverageWageReport: function(entity){
      let view = this;

      let url = "/api/sec/sotecExports/averageWageReport";
      let baseUrl = "/api/sec/sotecExports/averageWageReport";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("year", entity.year, url, baseUrl);
      url = this.$helpers.appendParam("month", entity.month, url, baseUrl);
      url = this.$helpers.appendParam("storeIds", entity.stores, url, baseUrl);
      url = this.$helpers.appendParam("wageTypes", entity.wageTypes, url, baseUrl);
      url = this.$helpers.appendParam("employmentModels", entity.models, url, baseUrl);

      return this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });

    },

    createAverageWageReportPdf(averageWageReportResponse, entity){
      let view = this;

      let stores = {};
      let storesLabel = "";
      Object.values(entity.stores).forEach(storeId => {
        let store = view.storeArray.find(s => s.id === storeId);
        if (store) {
          stores[storeId] = store;
          storesLabel = store.name + ", " + store.description;
        }
      });


      let headers = [
        {content: 'Beschäftigungsmodell', colSpan: 1, styles: {halign: 'left'}},
        {content: 'Gesamtarbeitszeit (h)', colSpan: 1, styles: {halign: 'right'}},
        {content: 'Basis Lohn (€)', colSpan: 1, styles: {halign: 'right'}},
        {content: 'Bonus Lohn (€)', colSpan: 1, styles: {halign: 'right'}},
        {content: 'Gesamt Lohn (€)', colSpan: 1, styles: {halign: 'right'}},
        {content: 'Durchschnitt Stundenlohn (€)', colSpan: 1, styles: {halign: 'right'}},
      ];
      let totalSums = [0, 0, 0, 0];
      let totalSumRow = ['Gesamt Summe'];

      let filename = 'Report_Stundenlohn_' + entity.year + '-' + entity.month + '.pdf';
      let body = [];
      let repDoc = this.$helpers.tableReportDoc(
          headers,
          'Stundenlohn Report ' + view.monthNames[entity.month - 1] + ' ' + entity.year,
          view.$store.state.companyData.label,
          Object.keys(stores).length > 1 ? "" : storesLabel,
          this.userFullname,
          9
      );
      let doc = repDoc.doc;
      let contentOffsetY = repDoc.contentOffsetY;
      doc.setFontSize(12);


      for (const [storeId, storeBalances] of Object.entries(averageWageReportResponse)) {

        if (Object.keys(stores).length > 1) {
          // store name
          let store = stores[storeId];
          body.push([{content: store.name + ", " + store.description, colSpan: 6}]);
        }

        let sums = [0, 0, 0, 0];

        for (const [employmentModelId, employmentModelBalances] of Object.entries(storeBalances)) {
          let workTime = employmentModelBalances.workTime / 60;
          let baseAmount = employmentModelBalances.baseAmount;
          let bonusAmount = employmentModelBalances.bonusAmount;
          let totalAmount = employmentModelBalances.baseAmount + employmentModelBalances.bonusAmount;
          let averageHourlyAmount = employmentModelBalances.workTime > 0 ? totalAmount / workTime : 0;

          let cur = [
            view.models.find(m => m.id == employmentModelId).name,
            workTime.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            baseAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            bonusAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            totalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            averageHourlyAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
          ];
          if (entity.includeNullRows || workTime > 0) {
            body.push(cur);
          }

          sums[0] += workTime;
          sums[1] += baseAmount;
          sums[2] += bonusAmount;
          sums[3] += totalAmount;

          totalSums[0] += workTime;
          totalSums[1] += baseAmount;
          totalSums[2] += bonusAmount;
          totalSums[3] += totalAmount;
        }

        let sumRow = ['Summe'];
        sumRow = sumRow.concat(sums.map(s => s.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")));
        sumRow.push(sums[0] > 0 ? (sums[3] / sums[0]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "0.00");

        body.push(sumRow);
        if (Object.keys(stores).length > 1) {
          body.push([]);
        }
      }

      totalSumRow = totalSumRow.concat(totalSums.map(s => s.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")));
      totalSumRow.push(totalSums[0] > 0 ? (totalSums[3] / totalSums[0]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "): "0.00");

      if (Object.keys(stores).length > 1) {
        body.push(totalSumRow);
      }

      doc.setProperties({
        title: "Export Stundenlohn Report"
      });

      doc.autoTable(
          {
            startY: contentOffsetY,
            head: [headers],
            body: body,
            headStyles: {
              fillColor: [229,21,80],
              fontSize: 7,
            },
            bodyStyles: {
              fontSize: 7
            },
            columnStyles: {
            },
            didParseCell: (data) => {
              if (data.section === 'body' && data.column.dataKey > 0 && data.cell.raw !== undefined) {
                data.cell.styles.halign = 'right';
              }
              if (data.section === 'body' && data.cell.raw !== undefined && !(typeof data.cell.raw === 'object')
                  && (data.cell.raw.includes("Summe") || data.row.cells[0].raw.includes("Summe"))) {
                data.cell.styles.fontStyle = 'bold';
              }
              if (data.section === 'body' && data.cell.raw !== undefined && data.row.cells[1] === undefined) {
                data.cell.styles.fontStyle = 'bold';
              }
            },
            didDrawCell: (data) => {
            }
          }
      );

      let y = doc.lastAutoTable.finalY + 20; // The y position on the page
      let x = 14;
      doc.setFontSize(8);
      doc.text("Bitte beachten Sie, dass für diesen Report die nachfolgenden Beschäftigungsgruppen und individuellen Lohnarten herangezogen wurden.", x, y);
      y += 4;
      doc.text("Die Durchschnittslöhne basieren daher ebenfalls nur auf den angeführten Beschäftigungsgruppen und individuellen Lohnarten.", x, y);
      y += 6;
      doc.text("Beschäftigungsmodelle:", x, y);
      let textSize = doc.getTextWidth("Beschäftigungsmodelle:");
      x += textSize + 3;
      let pageWidth = doc.internal.pageSize.getWidth();
      let modelsArray = doc.splitTextToSize(entity.models.map(m => view.models.find(model => model.id === m).name).join(", "), pageWidth-x-14);
      doc.text(modelsArray, x, y);
      modelsArray.forEach(ma => {
        y += 4;
      });
      x = 14;
      doc.text("Individuelle Lohnarten:", x, y);
      x += textSize + 3;
      let wageTypes = doc.splitTextToSize(entity.wageTypes.map(wt => view.wageTypes.find(wageType => wageType.id === wt).name).join(", "), pageWidth-x-14);
      doc.text(wageTypes, x, y);

      // console.log(entity.models);
      // console.log(entity.wageTypes);


      // var splitList = doc.splitTextToSize(reportTitle, 180);
      // doc.text(15, 20, splitList);


      this.modalPdfType = 'Report';
      view.$refs["modal-loadAverageWageReport"].hide();
      this.previewPdf(doc, filename);
    },

    previewPdf(doc, filename){
      var data = doc.output('datauristring');
      this.modalPdfFilename = filename;
      this.modalPdf = data;
      this.modalPdfDoc = doc;

      // console.log(filename);
      // console.log(data);
      // console.log(doc);

      this.showModal('modal-pdfpreview');
    },
    closePdfPreview(){
      this.hideModal('modal-pdfpreview');
      console.log('cleaning up after pdf preview close');
      this.modalPdf = '';
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    showModal(ref) {
      this.$refs[ref].show()
    },
    savePreviewedPdf(){
      this.modalPdfDoc.save(this.modalPdfFilename);
    },
    openPreviewedPdf(){
      this.modalPdfDoc.output('dataurlnewwindow');
      window.open(this.modalPdfDoc.output("bloburl"), "_blank");
    },

    showEmployeesWithoutIdModal(id, jsonError) {
      if (this.currentRowId !== id) {
        this.currentRowId = id;
        let employeesWithoutId = JSON.parse(jsonError).employeesWithoutId;
        Object.values(employeesWithoutId).forEach(e => {
          if (!e.hasOwnProperty('mdId')) {
            e.mdId = '';
          }
        });
        this.employeesWithoutId = JSON.parse(JSON.stringify(employeesWithoutId));
        this.employeesWithoutIdInput = JSON.parse(JSON.stringify(this.employeesWithoutId));
      }
      console.log(this.employeesWithoutId);
      this.$refs["modal-employeesWithoutId"].show();
    },
    closeEmployeesWithoutIdModal() {
      console.log(this.employeesWithoutId);
      this.employeesWithoutIdInput = JSON.parse(JSON.stringify(this.employeesWithoutId));
      this.$refs["modal-employeesWithoutId"].hide();
    },
    saveEmployeesWithoutIdModal() {
      console.log("saving...");
      console.log(this.employeesWithoutIdInput);
      this.saveEmployeeIds(this.employeesWithoutIdInput);
      this.$refs["modal-employeesWithoutId"].hide();
    },
    saveEmployeeIds(employeesWithoutId, generateChangePdf = false) {
      let view = this;
      let promises = [];

      Object.values(employeesWithoutId).forEach(emp => {
        let url = "/sec/employee/coredata/" + emp.id;

        let payload = {
          "mdPersonnelId": emp.mdId
        };

        promises.push(
          this.api.put(url, payload)
        );
      });

      let response200 = true;
      Promise.all(promises).then((responses) => {
        console.log(responses);

        Object.values(responses).forEach((response) => {
          if (response.status !== 200) {
            response200 = false;
          }
          let current = this.employeesWithoutId.find(e => {
            return e.id === response.data.id;
          });
          current.mdId = response.data.mdPersonnelId;
        });
      })
        .then((response) => {
          console.log(response200);
          if (response200) {
            console.log(this.employeesWithoutId);
            view.$helpers.success("Gespeichert", "Änderungen gespeichert!");
          }
        })
        .catch(function (error) {
          view.loading = false;
          console.log(error);
          console.log(error.message);
          view.$helpers.error(error.message);
        });
    },
    filledAllEmployeeIds() {
      let filledAll = true;
      Object.values(this.employeesWithoutId).forEach((emp) => {
        if (emp.mdId == null || emp.mdId === "") {
          filledAll = false;
        }
      });
      return filledAll;
    },

    sendReset: function (exportId) {
      let view = this;
      let url = "/api/sec/resetSotecExport";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/resetSotecExport");
      url = this.$helpers.appendParam("exportId", exportId, url, "/api/sec/resetSotecExport");
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      })
        .then((response) => {
          view.$helpers.success("Erfolg", "Der Export wurde neu gestartet!");
          view.refresh();
        })
        .catch((error) => {
          view.$helpers.error("Fehler", "Der Export konnte nicht neu gestartet werden!");
          view.refresh();
        });
    },
    sendUpload: function (exportId) {
      let view = this;
      let url = "/api/sec/uploadSotecExport";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/uploadSotecExport");
      url = this.$helpers.appendParam("exportId", exportId, url, "/api/sec/uploadSotecExport");
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      })
        .then((response) => {
          view.$helpers.success("Erfolg", "Der Export wird weiter verarbeitet!");
          view.refresh();
        })
        .catch((error) => {
          view.$helpers.error("Fehler", "Der Export konnte nicht weiterverarbeitet werden!");
          view.refresh();
        });
    },
    sendAbort: function (exportId) {
      let view = this;
      window.app.popupalerts.push({
        header: "Abbrechen bestätigen",
        number: null,
        description: "Soll der Export wirklich abgebrochen werden?",
        hidden: false,
        callbackAccept: function (popup) {
          popup.loading = true;
          let url = "/api/sec/abortSotecExport";
          url = view.$helpers.appendParam("companyId", view.$store.state.companyId, url, "/api/sec/abortSotecExport");
          url = view.$helpers.appendParam("exportId", exportId, url, "/api/sec/abortSotecExport");
          view
            .axios({
              method: "post",
              headers: {
                AUTHORIZATION: "Bearer " + view.$store.state.jwt,
              },
              url: url,
            })
            .then((response) => {
              view.$helpers.success("Erfolg", "Der Export wurde abgebrochen!");
              popup.loading = false;
              popup.hidden = true;
              view.refresh();
            })
            .catch((error) => {
              view.$helpers.error("Fehler", "Der Export konnte nicht abgebrochen werden!");
              popup.loading = false;
              popup.hidden = true;
              view.refresh();
            });
        },
        callbackCancel: function (popup) {
          popup.loading = false;
          popup.hidden = true;
        },
      });
    },

    refresh: function (changeTimeframe = false, force = false) {
      console.log("refresh");
      let view = this;
      view.sessionId = crypto.randomUUID();
      view.reset();
      view.loadingData = true;

      view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
          view.$helpers.GetStoresArray().then((stores) => {
            view.storesData = stores;
            if (view.selectedStore == null) {
              if (stores.length > 0) {
                view.selectedStore = stores[0].id;
                console.log("Auto selected first store:");
                console.log(view.selectedStore);
                view.$forceUpdate();
              }
            }
            view.loading = true;
            if (view.storesData.length > 0) {
              view.storesData = view.storeArray;
              let selectedStoreIndex = 0;
              for (let i = 0; i < view.storesData.length; i++) {
                if (view.storesData[i].id == view.selectedStore) {
                  selectedStoreIndex = i;
                }
              }

              view.storeData = view.storesData[selectedStoreIndex];
              //view.isBalanceConfirmationRequired = (view.storeData == null || view.storeData.isBalanceConfirmationRequired == null || view.storeData.isBalanceConfirmationRequired == undefined || view.storeData.isBalanceConfirmationRequired);
              if (view.companyData != null) {
                if (changeTimeframe) {
                  if (view.companyData != null && (true || view.softwareId == 2)) {
                    //BMD PFAD MONATLICH
                    view.selectedDate.subtract(1, "months");
                    view.timeframeStart = view.selectedDate.clone().startOf("month");
                    view.timeframeEnd = view.selectedDate.clone().endOf("month");
                    view.$forceUpdate();
                  }
                }
              }
              if (view.softwareId == 2) {
                view.interactionNeeded = [];
                view.storesExportMap = [];
                view
                  .getExports()
                  .then((response) => {
                    console.log(response);
                    for (let i in response.data.data) {
                      let sotecExport = response.data.data[i];
                      let tmpMoment = view.$helpers.getMomentFromStringTZ(sotecExport.created_at);
                      sotecExport.dateMoment = tmpMoment;

                      let tmpMonth = sotecExport.month - 1;
                      let tmpYear = sotecExport.year;
                      if (tmpMonth == 0) {
                        tmpMonth = 11;
                        tmpYear--;
                      }

                      if (sotecExport.month - 1 == view.selectedDate.month() && sotecExport.year == view.selectedDate.year()) {
                        view.storesExportMap.push(sotecExport);
                      }

                      if (view.canRetry(sotecExport.state) || sotecExport.state == 94 || sotecExport.state == 3) {
                        view.interactionNeeded.push(sotecExport);
                      } else if (sotecExport.state == 0 || sotecExport.state == 1 || sotecExport.state == 4) {
                        view.interactionNeeded.push(sotecExport);
                      }
                    }
                    view.loadingData = false;
                  })
                  .catch((error) => {
                    console.log("REFRESH ERROR: " + error);
                    view.loadingData = false;
                  });
                // load in parallel
                view.loadModels();
                view.loadWageTypes();
                view.$helpers.GetEmployeeMap()
                .then((response) => {
                  view.employeeMapById = [];
                  Object.values(view.employeeMap).forEach((emp) => {
                    view.employeeMapById[emp.id] = emp;
                  });
                });
              } else {
                view.loadingData = false;
              }
            }
          });
        })
        .catch((error) => {
          console.log("REFRESH ERROR: " + error);
          view.loadingData = false;
        });
    },
    updateCompanySettings: function (response) {
      let view = this;
      if (response.data.length == 1) {
        view.companyData = response.data[0];
        view.softwareId = view.companyData.softwareId;
      }
    },

    loadModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
      .GetCall("/api/sec/employmentModels", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
          };
          tmp.push(item);
        }
        view.models = tmp.slice();
        console.log(view.models);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    loadWageTypes: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
      .GetCall("/api/sec/individualWageTypes", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            numberString: data[i].numberString,
            name: data[i].name,
            details: data[i].details,
          };
          tmp.push(item);
        }
        view.wageTypes = tmp.slice();
        console.log(view.wageTypes);
      })
      .catch((e) => {
        console.log(e);
      });
    },

    modDate: function (method) {
      let view = this;
      if (view.storeData != null && view.softwareId == 2) {
        //BMD PFAD MONATLICH
        if (method == "add") {
          this.selectedDate.add(1, "months");
          this.timeframeStart = this.selectedDate.clone().startOf("month");
          this.timeframeEnd = this.selectedDate.clone().endOf("month");
          this.$forceUpdate();
        } else if (method == "sub") {
          this.selectedDate.subtract(1, "months");
          this.timeframeStart = this.selectedDate.clone().startOf("month");
          this.timeframeEnd = this.selectedDate.clone().endOf("month");
          this.$forceUpdate();
        }
      }
      this.refresh();
    },

    jobEnded: function (object) {
      let view = this;

      view.getExport(object.id)
        .then((response) => {
          let sotecExport = response.data;
          let tmpMoment = view.$helpers.getMomentFromStringTZ(sotecExport.created_at);
          sotecExport.dateMoment = tmpMoment;

          let tmpMonth = sotecExport.month - 1;
          let tmpYear = sotecExport.year;
          if (tmpMonth == 0) {
            tmpMonth = 11;
            tmpYear--;
          }

          let storesExport = view.storesExportMap.find(e => e.id === sotecExport.id);
          if (storesExport != undefined) {
            storesExport.state = sotecExport.state;
            storesExport.errorJson = sotecExport.errorJson;
          }
          let interactionNeededExport = view.interactionNeeded.find(e => e.id === sotecExport.id);
          if (interactionNeededExport != undefined) {
            interactionNeededExport.state = sotecExport.state;
            interactionNeededExport.errorJson = sotecExport.errorJson;
          }
          view.$forceUpdate();
          view.$helpers.success("Erfolg", "Der Export wurde abgeschlossen!");
        });
    },

    getEmployeeContracts: function () {
      let view = this;
      let url = '/api/sec/proxy/md/employee/contracts';

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');

      return this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then(resp => {
        return view.$helpers.GetStores()
          .then(storeResponse => {
            let storeIds = storeResponse.map(s => s.id);
            view.contracts = {};
            Object.values(resp.data).forEach(contract => {
              if (storeIds.includes(contract.storeId)) {
                if (view.contracts.hasOwnProperty(contract.remoteId)) {
                  view.contracts[contract.remoteId].push(contract);
                } else {
                  view.contracts[contract.remoteId] = [];
                  view.contracts[contract.remoteId].push(contract);
                }
              }
            });
            // console.log(view.contracts);
          });
      }).catch(error => {
        console.log(error);
      });
    },
  },
};
</script>
<style scoped>
.buttonml {
  background-color: var(--ml);
}.buttonml:hover {
  background-color: var(--contrast-4);
}
.labelml {
  color: var(--bg);
}

.employee {
  height: 40px;
  width: 150px;
  margin: 5px;

  position: relative;
  box-shadow: 0 0 1px 1px var(--contrast-3);
  padding: 5px;
  float: left;
}

.activeTab {
  background: var(--ml) !important;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
}
.inactiveTab {
  background: var(--contrast-1) !important;
  color: #555 !important;
  font-weight: bold;
  cursor: pointer;
}

.activeTab:hover {
  background: var(--contrast-4) !important;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
}
.inactiveTab:hover {
  background: var(--contrast-4) !important;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
}
</style>
